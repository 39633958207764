import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Alert Dialog" description="Accessible alert dialog component for React" mdxType="SEO" />
    <h1 {...{
      "id": "alert-dialog"
    }}>{`Alert Dialog`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#alertdialog" mdxType="TOCLink">AlertDialog</TOCLink>
    <TOCLink href="#alertdialogcontent" mdxType="TOCLink">AlertDialogContent</TOCLink>
    <TOCLink href="#alertdialogdescription" mdxType="TOCLink">AlertDialogDescription</TOCLink>
    <TOCLink href="#alertdialogoverlay" mdxType="TOCLink">AlertDialogOverlay</TOCLink>
    <TOCLink href="#alertdialogcontent" mdxType="TOCLink">AlertDialogContent</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/alert-dialog"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/alert-dialog`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#alertdialog"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#alertdialog`}</a></li>
    </ul>
    <p>{`A modal dialog that interrupts the user's workflow to get a response, usually some sort of confirmation. This is different than a typical Dialog in that it requires some user response, like "Save", or "Cancel", etc.`}</p>
    <p>{`Most of the time you'll use `}<inlineCode parentName="p">{`AlertDialog`}</inlineCode>{`, `}<inlineCode parentName="p">{`AlertDialogLabel`}</inlineCode>{`, and `}<inlineCode parentName="p">{`AlertDialogDescription`}</inlineCode>{` together. If you need more control over the styling of the modal you can drop down a level and use `}<inlineCode parentName="p">{`AlertDialogOverlay`}</inlineCode>{` and `}<inlineCode parentName="p">{`AlertDialogContent`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`AlertDialog`}</inlineCode>{`.`}</p>
    <p>{`When a Dialog opens, the `}<em parentName="p">{`least destructive`}</em>{` action should be focused so that if a user accidentally hits enter when the dialog opens no damage is done. This is accomplished with the `}<inlineCode parentName="p">{`leastDestructiveRef`}</inlineCode>{` prop.`}</p>
    <p>{`Every dialog must render an `}<inlineCode parentName="p">{`AlertDialogLabel`}</inlineCode>{` so the screen reader knows what to say about the dialog. If an `}<inlineCode parentName="p">{`AlertDialogDescription`}</inlineCode>{` is also rendered, the screen reader will also announce that. If you render more than these two elements and some buttons, the screen reader might not announce it so it's important to keep the content inside of `}<inlineCode parentName="p">{`AlertDialogLabel`}</inlineCode>{` and `}<inlineCode parentName="p">{`AlertDialogDescription`}</inlineCode>{`.`}</p>
    <p>{`This is built on top of `}<a parentName="p" {...{
        "href": "/dialog"
      }}>{`Dialog`}</a>{`, so `}<inlineCode parentName="p">{`AlertDialog`}</inlineCode>{` spreads its props and renders a `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{`, same for `}<inlineCode parentName="p">{`AlertDialogOverlay`}</inlineCode>{` to `}<inlineCode parentName="p">{`DialogOverlay`}</inlineCode>{`, and `}<inlineCode parentName="p">{`AlertDialogContent`}</inlineCode>{` to `}<inlineCode parentName="p">{`DialogContent`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const cancelRef = React.useRef();
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Delete something</button>

      {showDialog && (
        <AlertDialog leastDestructiveRef={cancelRef}>
          <AlertDialogLabel>Please Confirm!</AlertDialogLabel>

          <AlertDialogDescription>
            Are you sure you want to delete something? This action is permanent,
            and we're totally not just flipping a field called "deleted" to
            "true" in our database, we're actually deleting something.
          </AlertDialogDescription>

          <div className="alert-buttons">
            <button onClick={close}>Yes, delete</button>{" "}
            <button ref={cancelRef} onClick={close}>
              Nevermind, don't delete.
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/alert-dialog`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/alert-dialog`}</inlineCode>{`. Then import the components that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/alert-dialog
# or
yarn add @reach/alert-dialog
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@reach/alert-dialog";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "alertdialog"
    }}>{`AlertDialog`}</h3>
    <p>{`High-level component to render an alert dialog.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialog leastDestructiveRef={someRef}>
  <AlertDialogLabel />
  <AlertDialogContent />
</AlertDialog>
`}</code></pre>
    <h4 {...{
      "id": "alertdialog-props"
    }}>{`AlertDialog Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialog-dialog-props"
            }}><inlineCode parentName="a">{`Dialog`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialog-isopen"
            }}><inlineCode parentName="a">{`isOpen`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialog-ondismiss"
            }}><inlineCode parentName="a">{`onDismiss`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialog-leastdestructiveref"
            }}><inlineCode parentName="a">{`leastDestructiveRef`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-element-props"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "alertdialog-dialog-props"
    }}>{`AlertDialog Dialog props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` element, which in turn is spread onto the underlying `}<inlineCode parentName="p">{`div[data-reach-dialog-content]`}</inlineCode>{`.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/dialog#dialog-props"
      }}>{`documentation for `}<inlineCode parentName="a">{`Dialog`}</inlineCode>{` props`}</a>{` for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialog style={{ color: "red" }}>
  <AlertDialogLabel>
    My text is red because the style prop got applied to the div underneath
  </AlertDialogLabel>
</AlertDialog>
`}</code></pre>
    <h5 {...{
      "id": "alertdialog-isopen"
    }}>{`AlertDialog isOpen`}</h5>
    <p><inlineCode parentName="p">{`isOpen?: boolean`}</inlineCode></p>
    <p>{`Controls whether the dialog is open or not. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialog isOpen={true}>
  <AlertDialogLabel>I will be open</AlertDialogLabel>
</AlertDialog>

<AlertDialog isOpen={false}>
  <AlertDialogLabel>I will be closed</AlertDialogLabel>
</AlertDialog>
`}</code></pre>
    <p>{`Note, however, that the dialog will not render to the DOM when `}<inlineCode parentName="p">{`isOpen={false}`}</inlineCode>{`, but you may want to save on the number of elements created in your render function. If you'd rather not have the dialog always rendered, you can put a guard in front of it and only render when it should be open. In this case you don’t need the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` prop at all.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
  state.confirmDelete && (
    <AlertDialog isOpen={true}>
      <AlertDialogLabel>I will be open</AlertDialogLabel>
    </AlertDialog>
  );
}
`}</code></pre>
    <p>{`You should probably do this when your dialog contains a lot of elements. It's also useful for transition animations.`}</p>
    <h5 {...{
      "id": "alertdialog-ondismiss"
    }}>{`AlertDialog onDismiss`}</h5>
    <p><inlineCode parentName="p">{`onDismiss?: (event?: React.SyntheticEvent) => void;`}</inlineCode></p>
    <p>{`When the user clicks outside the modal or hits the escape key, this function will be called. If you want the modal to close, you’ll need to set state.`}</p>
    <p><strong parentName="p">{`IMPORTANT`}</strong>{`: Ensure that `}<inlineCode parentName="p">{`onDismiss`}</inlineCode>{` and the click handler of the `}<inlineCode parentName="p">{`leastDestructiveRef`}</inlineCode>{` are identical!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const cancelRef = React.useRef();

  const open = () => setShowDialog(true);
  const destroyStuff = () => {
    console.log("Destroyed!");
    setShowDialog(false);
  };
  // make sure \`close\` and the \`onClick\` of the
  // \`leastDestructiveRef\` are identical, best to just
  // pass them both the same function
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Delete something</button>

      {showDialog && (
        <AlertDialog onDismiss={close} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel>Please Confirm!</AlertDialogLabel>

          <AlertDialogDescription>
            Are you sure you want to delete something? This action is permanent,
            and we're totally not just flipping a field called "deleted" to
            "true" in our database, we're actually deleting something.
          </AlertDialogDescription>

          <div className="alert-buttons">
            <button onClick={destroyStuff}>Yes, delete</button>{" "}
            <button ref={cancelRef} onClick={close}>
              Nevermind, don't delete.
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "alertdialog-leastdestructiveref"
    }}>{`AlertDialog leastDestructiveRef`}</h5>
    <p><inlineCode parentName="p">{`leastDestructiveRef?: React.RefObject<HTMLElement>;`}</inlineCode></p>
    <p>{`To prevent accidental data loss, an alert dialog should focus the least destructive action button when it opens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);

  // we'll pass this ref to both AlertDialog and our button
  const cancelRef = React.useRef();

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Publish something</button>

      {showDialog && (
        <AlertDialog leastDestructiveRef={cancelRef}>
          <AlertDialogLabel>Please Confirm!</AlertDialogLabel>

          <AlertDialogDescription>
            Are you sure you want to publish this thing?
          </AlertDialogDescription>

          <div className="alert-buttons">
            <button onClick={this.close}>Yes, publish and keep editing</button>{" "}
            <button onClick={this.close}>Yes, publish and view</button>{" "}
            <button ref={cancelRef} onClick={close}>
              Don't publish, keep working
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "alertdialog-children"
    }}>{`AlertDialog children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Accepts any renderable content but should generally be restricted to `}<inlineCode parentName="p">{`AlertDialogLabel`}</inlineCode>{`, `}<inlineCode parentName="p">{`AlertDialogDescription`}</inlineCode>{` and action buttons, other content might not be announced to the user by the screen reader.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialog>
  <AlertDialogLabel>Please Confirm!</AlertDialogLabel>

  <AlertDialogDescription>A longer message</AlertDialogDescription>

  <div>
    <button onClick={destroyStuff}>A Destructive Action</button>{" "}
    <button ref={leastDestructiveRef}>Least Destructive Action</button>
  </div>
</AlertDialog>
`}</code></pre>
    <h3 {...{
      "id": "alertdialoglabel"
    }}>{`AlertDialogLabel`}</h3>
    <p>{`The first thing ready by screen readers when the dialog opens, usually the title of the dialog like "Warning!" or "Please confirm!".`}</p>
    <p>{`This is required. The `}<inlineCode parentName="p">{`AlertDialog`}</inlineCode>{` will throw an error if no label is rendered.`}</p>
    <h4 {...{
      "id": "alertdialoglabel-css-selectors"
    }}>{`AlertDialogLabel CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`Use the following CSS to target the label:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-alert-dialog-label] {
}
`}</code></pre>
    <h4 {...{
      "id": "alertdialoglabel-props"
    }}>{`AlertDialogLabel Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialoglabel-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "alertdialoglabel-div-props"
    }}>{`AlertDialogLabel div props`}</h5>
    <p>{`All props are spread to an underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialogLabel className="alert-label" />
`}</code></pre>
    <h3 {...{
      "id": "alertdialogdescription"
    }}>{`AlertDialogDescription`}</h3>
    <p>{`Additional content read by screen readers, usually a longer description about what you need from the user like "This action is permanent, are you sure?" etc.`}</p>
    <h4 {...{
      "id": "alertdialogdescription-css-selectors"
    }}>{`AlertDialogDescription CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`Use the following CSS to target the description:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-alert-dialog-description] {
}
`}</code></pre>
    <h4 {...{
      "id": "alertdialogdescription-props"
    }}>{`AlertDialogDescription Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogdescription-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "alertdialogdescription-div-props"
    }}>{`AlertDialogDescription div props`}</h5>
    <p>{`All props are spread to an underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialogDescription className="alert-description" />
`}</code></pre>
    <h3 {...{
      "id": "alertdialogoverlay"
    }}>{`AlertDialogOverlay`}</h3>
    <p>{`Low-level component if you need more control over the styles or rendering of the dialog overlay. In the following example we use the AlertDialogOverlay and AlertDialogContent to have more control over the styles.`}</p>
    <p>{`Note: You must render an `}<inlineCode parentName="p">{`AlertDialogContent`}</inlineCode>{` inside.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);

  // we'll pass this ref to both AlertDialog and our button
  const cancelRef = React.useRef();

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Delete something</button>

      {showDialog && (
        <AlertDialogOverlay
          style={{ background: "hsla(0, 50%, 50%, 0.85)" }}
          leastDestructiveRef={cancelRef}
        >
          <AlertDialogContent style={{ background: "#f0f0f0" }}>
            <AlertDialogLabel>Please Confirm!</AlertDialogLabel>

            <AlertDialogDescription>
              Are you sure you want delete stuff, it will be permanent.
            </AlertDialogDescription>

            <div className="alert-buttons">
              <button onClick={close}>Yes, delete</button>{" "}
              <button ref={cancelRef} onClick={close}>
                Nevermind
              </button>
            </div>
          </AlertDialogContent>
        </AlertDialogOverlay>
      )}
    </div>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "alertdialogoverlay-css-selectors"
    }}>{`AlertDialogOverlay CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`Use the following CSS to target the overlay:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-alert-dialog-overlay] {
}
`}</code></pre>
    <h4 {...{
      "id": "alertdialogoverlay-props"
    }}>{`AlertDialogOverlay Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogoverlay-dialogoverlay-props"
            }}><inlineCode parentName="a">{`DialogOverlay`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogoverlay-isopen"
            }}><inlineCode parentName="a">{`isOpen`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogoverlay-ondismiss"
            }}><inlineCode parentName="a">{`onDismiss`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogoverlay-leastdestructiveref"
            }}><inlineCode parentName="a">{`leastDestructiveRef`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogoverlay-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "alertdialogoverlay-dialogoverlay-props"
    }}>{`AlertDialogOverlay DialogOverlay props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`DialogOverlay`}</inlineCode>{`, and in turn spread onto the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` element.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/dialog#dialogoverlay-props"
      }}>{`documentation for `}<inlineCode parentName="a">{`DialogOverlay`}</inlineCode>{` props`}</a>{` for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialogOverlay className="light-modal" />
`}</code></pre>
    <h5 {...{
      "id": "alertdialogoverlay-isopen"
    }}>{`AlertDialogOverlay isOpen`}</h5>
    <p><inlineCode parentName="p">{`isOpen?: boolean`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#alertdialog-isopen"
      }}><inlineCode parentName="a">{`AlertDialog`}</inlineCode>{` `}<inlineCode parentName="a">{`isOpen`}</inlineCode></a>{`. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "alertdialogoverlay-ondismiss"
    }}>{`AlertDialogOverlay onDismiss`}</h5>
    <p><inlineCode parentName="p">{`onDismiss?: (event?: React.SyntheticEvent) => void`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#alertdialog-ondismiss"
      }}><inlineCode parentName="a">{`AlertDialog`}</inlineCode>{` `}<inlineCode parentName="a">{`onDismiss`}</inlineCode></a></p>
    <h5 {...{
      "id": "alertdialogoverlay-leastdestructiveref"
    }}>{`AlertDialogOverlay leastDestructiveRef`}</h5>
    <p><inlineCode parentName="p">{`leastDestructiveRef?: React.RefObject<HTMLElement>`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#alertdialog-leastdestructiveref"
      }}><inlineCode parentName="a">{`AlertDialog`}</inlineCode>{` `}<inlineCode parentName="a">{`leastDestructiveRef`}</inlineCode></a></p>
    <h5 {...{
      "id": "alertdialogoverlay-children"
    }}>{`AlertDialogOverlay children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Should be an `}<inlineCode parentName="p">{`AlertDialogContent`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialogOverlay>
  <AlertDialogContent />
</AlertDialogOverlay>
`}</code></pre>
    <h3 {...{
      "id": "alertdialogcontent"
    }}>{`AlertDialogContent`}</h3>
    <p>{`Low-level component if you need more control over the styles or rendering of the dialog content.`}</p>
    <p>{`Note: Must be a child of `}<inlineCode parentName="p">{`AlertDialogOverlay`}</inlineCode>{`.`}</p>
    <p>{`Note: You only need to use this when you are also styling `}<inlineCode parentName="p">{`AlertDialogOverlay`}</inlineCode>{`, otherwise you can use the high-level `}<inlineCode parentName="p">{`AlertDialog`}</inlineCode>{` component and pass the props to it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialogOverlay style={someSpecificStyles}>
  <AlertDialogContent className={orAClassName} />
</AlertDialogOverlay>
`}</code></pre>
    <h4 {...{
      "id": "alertdialogcontent-css-selectors"
    }}>{`AlertDialogContent CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`Use the following CSS to target the content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-alert-dialog-content] {
}
`}</code></pre>
    <h4 {...{
      "id": "alertdialogcontent-props"
    }}>{`AlertDialogContent Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogcontent-dialogcontent-props"
            }}><inlineCode parentName="a">{`DialogContent`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alertdialogcontent-element-props"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "alertdialogcontent-dialogcontent-props"
    }}>{`AlertDialogContent DialogContent props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`DialogContent`}</inlineCode>{` element, and then again onto the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/dialog#dialogcontent-props"
      }}>{`documentation for `}<inlineCode parentName="a">{`DialogContent`}</inlineCode>{` props`}</a>{` for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<AlertDialogContent className="nice-border" />
`}</code></pre>
    <h5 {...{
      "id": "alertdialogcontent-children"
    }}>{`AlertDialogContent children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#alertdialog-children"
      }}><inlineCode parentName="a">{`AlertDialog`}</inlineCode>{` `}<inlineCode parentName="a">{`children`}</inlineCode></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      